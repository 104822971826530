<div class="row mx-0" style="background-color: #f2f2f2;">
    <div class="col-12 mt-3 px-0 opensans-light">
        <div class="row mx-0">

            <!-- for unmoderated video -->
            <div class="col-12 px-0 text-left bg-white box-shadow-section"
                *ngIf="video && video.topBtnColor == 'orange' && video.bottomBtnColor == 'orange'">
                <div class="row mx-0">
                    <div class="" style="width: 43%;">
                        <ziggeo-player #ziggeoplayer [apiKey]="apiKey" [options]="{
                            theme:'modern',
                            height: 300,
                            width: 250,
                            video:video.videoid
                        }">
                        </ziggeo-player>
                    </div>
                    <div class="px-2" style="width: 50%;">

                        <div class="small-text c-darkergray pl-2 pt-3">
                            {{video.photouploadtime | date:'medium'}}
                            <span class="pl-2 oswald">
                                <span>
                                    <img src="../../../assets/images/static/selfie-video-camera-icon.svg"
                                        alt="">
                                </span>
                            </span>
                        </div>
                       

                        <div class="" *ngIf="video.reviewerAction === false">
                            <div class="c-darkgray small-text pl-2 pt-2">
                                By reviewer <b>{{video.reviewedBy}}</b> {{video.reviewerModerationTime | date:'medium'}}
                                <div class="small-text c-darkgray py-1">Reason for rejecting Video</div>
                            </div>

                            <div class="pl-2" *ngIf="declineReasonsByReviewer">
                                <div class="small-text c-darkergray py-1">
                                    {{declineReasonsByReviewer.split('; ')[0]}}
                                    <span class="readMore" (click)="declineDialog(true)">READ MORE</span>
                                </div>
                            </div>
                        </div>

                        <div class="" *ngIf="video.reviewerAction">
                            <div class="c-darkgray small-text pl-2 pt-2">
                                By reviewer <b>{{video.reviewedBy}}</b> {{video.reviewerModerationTime | date:'medium'}}
                            </div>
                            
                        </div>


                        <div class="" *ngIf="video.supervisorAction === false && !video.unmoderated">
                            <div class="c-darkgray small-text pl-2 pt-2">
                                <span class="d-inline-block moderation-word-bg-red rounded-circle position-relative"
                                    style="height: 20px;width: 20px;">
                                    <img class="center-box" [src]="btnObj.cross.white" alt="tick" height="15"
                                        width="15" />
                                </span> By supervisor <b>{{video.moderatedBy}}</b> {{video.supervisorModerationTime |
                                date:'medium'}}
                                <div class="small-text c-darkgray py-1">Reason for rejecting Video</div>
                            </div>

                            <div class="pl-2" *ngIf="declineReasonsBySupervisor">
                                <div class="small-text c-darkergray py-1">
                                    {{declineReasonsBySupervisor.split('; ')[0]}}
                                    <span class="readMore" (click)="declineDialog(true)">READ MORE</span>
                                </div>
                            </div>
                        </div>


                        <div class="" *ngIf="video.supervisorAction && !video.unmoderated">
                            <!-- <span class="d-inline-block bg-green rounded-circle" style="height: 25px;width: 25px;">
                                <img [src]="btnObj.tick.white" alt="tick">
                            </span> -->
                            <div class="c-darkgray small-text pl-2 pt-2">
                                By supervisor <b>{{video.moderatedBy}}</b> {{video.supervisorModerationTime |
                                date:'medium'}}
                            </div>
                        </div>

                    </div>

                    <div class="" style="width: 7%">

                        <button matRipple class="w-100 pb-1 d-block" style="height: 50%;"
                            (click)="userVideoAction(true)" [disabled]="video.disabledselfieVideoButton"
                            [ngClass]="[video.topBtnColor, 'moderation-word-bg-'+video.topBtnColor]">
                            <img *ngIf="video.topBtnColor !== 'green'"
                                [src]="video.topBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                alt="btn-tick" height="25" width="25">
                            <span *ngIf="video.topBtnColor === 'green'" matTooltip="APPROVED"
                                matTooltipClass="toolTipBgColor" class="d-block c-white small-text">
                                <ul class="list-inline" style="margin: auto 0;height: 100%;">
                                    <li *ngFor="let item of ['A','P','P','R','O','V','E','D']">
                                        {{item}}
                                    </li>
                                </ul>
                            </span>
                        </button>
                        <button matRipple class="w-100 pb-1 d-block" style="height: 50%;" (click)="declineDialog(false)"
                            [ngClass]="[video.bottomBtnColor, 'moderation-word-bg-'+video.bottomBtnColor]">
                            <img *ngIf="video.bottomBtnColor !== 'red'"
                                [src]="video.bottomBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                alt="cross-btn" height="25" width="25">
                            <span *ngIf="video.bottomBtnColor === 'red'" matTooltip="REJECTED"
                                matTooltipClass="toolTipBgColor" class="d-block c-white small-text">
                                <ul class="list-inline" style="margin: auto 0;height: 100%;">
                                    <li *ngFor="let item of ['R','E','J','E','C','T','E','D']">
                                        {{item}}
                                    </li>
                                </ul>
                            </span>
                        </button>
                    </div>
                </div>
            </div>


            <ng-container *ngFor="let item of photoArr;let i = index">
                <div class="col-12 px-0 my-3 bg-white box-shadow-section">
                    <div class="row mx-0">
                        <div class="clickable" style="width: 43%;" (click)="openFullViewImage(item.imageurl)">
                            <div class="position-relative w-100" style="padding-top: 100%;">
                                <!-- [ngStyle]="{'background-image': 'url('+ item.imageurl +')'}" -->
                                <div class="position-absolute img-square-landscape"
                                    *ngIf="item.tempwidth > item.tempheight">
                                    <img [src]="item.imageurl" alt="" class="photo-size-landscape">
                                </div>
                                <div class="position-absolute img-square" *ngIf="item.tempwidth <= item.tempheight">
                                    <img [src]="item.imageurl" alt="" class="photo-size">
                                </div>
                            </div>
                        </div>
                        <div class="px-2 text-left" style="width: 50%;">
                            <div class="small-text c-darkergray my-2 pl-3">
                                <span> {{item.photouploadtime | date:'medium'}}</span>
                                <span class="pl-2 oswald" *ngIf="item.imageType !== 'image'">
                                    <!-- <i>{{item.imageType}}</i> -->
                                    <span class="pl-2 oswald" *ngIf="item.imageType !== 'image'">
                                        <span *ngIf="item.imageType == 'deploma'">
                                            <img src="../../../../assets/images/static/degree-verified.svg" alt="">
                                        </span>
                                        <span *ngIf="item.imageType == 'photoid'">
                                            <img src="../../../../assets/images/static/photoID-verified.svg" alt="">
                                        </span>
                                        <span *ngIf="item.imageType == 'employeeId'">
                                            <img src="../../../../assets/images/static/employee-verified.svg" alt="">
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <!-- for action taken on photos -->
                            <div class="d-flex" *ngIf="(item.imageType === 'image') && item.checkActionTaken === false">
                                <div class="">
                                    <mat-checkbox class="c-btn-primary" [(ngModel)]="item.markasprofile"
                                        (change)="makeProfilePhoto(i,$event)"></mat-checkbox>
                                </div>
                                <div class="small-text c-darkergray pl-2">
                                    <div class="">
                                        Make profile picture
                                    </div>
                                    <div class="pt-1">
                                        {{item.photoApprovedtime | date:'medium'}}
                                    </div>
                                </div>
                            </div>


                            <!-- for profile photo -->
                            <div class="d-flex"
                                *ngIf="(item.imageType === 'image') && item.markasprofile && tempCheckForProfilePhoto == 1 && item.checkActionTaken === true">
                                <div class="" *ngIf=" photoCount > 1">
                                    <mat-checkbox class="c-btn-primary" [(ngModel)]="item.markasprofile"
                                        (change)="makeProfilePhoto(i,$event)"></mat-checkbox>
                                </div>
                                <div class="" *ngIf="item.checkActionTaken === true && photoCount == 1">
                                    <mat-checkbox class="c-btn-primary" disabled [(ngModel)]="item.markasprofile"
                                        (change)="makeProfilePhoto(i,$event)"></mat-checkbox>
                                </div>
                                <div class="" *ngIf="item.checkActionTaken === false && photoCount == 1">
                                    <mat-checkbox class="c-btn-primary" [(ngModel)]="item.markasprofile"
                                        (change)="makeProfilePhoto(i,$event)"></mat-checkbox>
                                </div>
                                <div class="small-text c-darkergray pl-2">
                                    <div class="">
                                        Make profile picture
                                    </div>
                                    <div class="pt-1">
                                        {{item.photoApprovedtime | date:'medium'}}
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="d-flex mt-2">
                                <div class="">
                                    <mat-checkbox></mat-checkbox>
                                </div>
                                <div class="small-text c-darkergray pl-2">
                                    Disallow as profile picture
                                </div>
                            </div> -->
                            <div class="pl-3 pt-2" *ngIf="item.imageType === 'image'">
                                <div class="">
                                    <span class="c-darkgray small-text">
                                        Caption
                                    </span>
                                    <span *ngIf="item.descriptionunmoderated">
                                        <button mat-icon-button matTooltip="Edit" matTooltipClass="toolTipBgColor"
                                            *ngIf="(item.descriptionunmoderated && !isReviewer)"
                                            (click)="captionEditIndex = i" style="vertical-align: middle;">
                                            <img class="center-box" [src]="btnObj.action.edit" height="20" width="20"
                                                alt="edit-btn">
                                        </button>

                                        <button mat-icon-button matTooltip="approved-icon"
                                            matTooltipClass="toolTipBgColor" class="border-0 bg-white rounded-circle"
                                            (click)="changeStatus(i,true,item,'caption')"
                                            [disabled]="item.disablePhotoApproveButton" style="vertical-align: middle;">
                                            <span class="d-block rounded-circle position-relative mx-auto"
                                                [ngClass]="[item.descriptionLeftBtnColor, 'moderation-word-bg-'+item.descriptionLeftBtnColor]"
                                                style="height: 20px;width: 20px;">
                                                <img class="center-box"
                                                    [src]="item.descriptionLeftBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                    height="12" width="12" alt="Approved-btn">
                                            </span>
                                        </button>

                                        <!-- <button mat-icon-button matTooltip="rejected-icon"
                                            matTooltipClass="toolTipBgColor" class="border-0 bg-white rounded-circle"
                                            *ngIf="item.descriptionLeftBtnColor !== 'red'"
                                            (click)="changeStatus(i,false,item,'caption')" style="margin-top: -11px;">
                                            <span class="d-block rounded-circle position-relative mx-auto"
                                                [ngClass]="[item.descriptionRightBtnColor, 'moderation-word-bg-'+item.descriptionRightBtnColor]"
                                                style="height: 20px;width: 20px;">
                                                <img class="center-box"
                                                    [src]="item.descriptionRightBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                                    height="12" width="12" alt="edit-btn">
                                            </span>
                                        </button> -->
                                    </span>

                                    <span *ngIf="!item.descriptionunmoderated">
                                        <button mat-icon-button matTooltip="approved-icon"
                                            matTooltipClass="toolTipBgColor" class="border-0 bg-white rounded-circle"
                                            (click)="changeStatus(i,true,item,'caption')"
                                            [disabled]="item.disablePhotoApproveButton" style="vertical-align: middle;">
                                            <span class="d-block rounded-circle position-relative mx-auto"
                                                [ngClass]="[item.descriptionLeftBtnColor, 'moderation-word-bg-'+item.descriptionLeftBtnColor]"
                                                style="height: 20px;width: 20px;">
                                                <img class="center-box"
                                                    [src]="item.descriptionLeftBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                    height="12" width="12" alt="Approved-btn">
                                            </span>
                                        </button>
                                    </span>
                                </div>
                                <div class="mt-1" *ngIf="item.imageType === 'image'">
                                    <div class="c-darkgray smaller-text" (keyup)="saveCaption($event,item)"
                                        (keydown.esc)="changeMind($event, item)"
                                        [ngClass]="{'caption-edit-class': captionEditIndex === i}"
                                        [attr.contenteditable]="captionEditIndex === i">
                                        <div>
                                            {{item.description ? item.description : 'N.A.'}}
                                        </div>

                                    </div>
                                    <!-- <div class="c-darkergray smaller-text mb-1" *ngIf="captionEditIndex === i">
                                        <i>Press ESC to cancel & ENTER to save</i>
                                    </div> -->
                                </div>
                                <div class="c-darkgray smaller-text" *ngIf="item.description1">
                                    {{item.description1}} By Reviewer
                                </div>
                            </div>


                            <div class="" *ngIf="item.reviewerAction === false">
                                <div class="c-darkgray small-text pl-2 pt-2">
                                    By reviewer <b>{{item.reviewedBy}}</b> {{item.reviewerModerationTime |
                                    date:'medium'}}
                                    <div class="small-text c-darkgray py-1">Reason for rejecting Photo</div>
                                </div>

                                <div class="pl-2" *ngIf="item.reason">
                                    <div class="small-text c-darkergray py-1">
                                        {{item.reason.split('; ')[0]}}
                                        <span class="readMore"
                                            (click)="openPhotoDeclineDialog(i,false,item,'img',true)">READ MORE</span>
                                    </div>
                                </div>
                            </div>

                            <div class="" *ngIf="item.reviewerAction">
                                <div class="c-darkgray small-text pl-2 pt-2">
                                    By reviewer <b>{{item.reviewedBy}}</b> {{item.reviewerModerationTime |
                                    date:'medium'}}
                                </div>
                            </div>


                            <div class="" *ngIf="item.supervisorAction === false">
                                <div class="c-darkgray small-text pl-2 pt-2">
                                    By supervisor <b>{{item.moderatedBy}}</b> {{item.supervisorModerationTime |
                                    date:'medium'}}
                                    <div class="small-text c-darkgray py-1">Reason for rejecting Photo</div>
                                </div>

                                <div class="pl-2" *ngIf="item.declinereason">
                                    <div class="small-text c-darkergray py-1">
                                        {{item.declinereason.split('; ')[0]}}
                                        <span class="readMore"
                                            (click)="openPhotoDeclineDialog(null,null,item,null,true)">READ MORE</span>
                                    </div>
                                </div>
                            </div>


                            <div class="" *ngIf="item.supervisorAction">
                                <div class="c-darkgray small-text pl-2 pt-2">
                                    By supervisor <b>{{item.moderatedBy}}</b> {{item.supervisorModerationTime |
                                    date:'medium'}}
                                </div>
                            </div>
                        </div>
                        <div class="" style="width: 7%;">

                            <button matRipple class="w-100 pb-1 d-block" style="height: 50%;"
                                [ngClass]="[item.topBtnColor, 'moderation-word-bg-'+item.topBtnColor]"
                                (click)="changeStatus(i,true,item,'img')"
                                [disabled]="isReviewer ?  (item.disablePhotoApproveButton || item.bottomBtnColor === 'gray') :   (item.bottomBtnColor === 'red' || item.disablePhotoApproveButton)">
                                <img *ngIf="item.topBtnColor !== 'green'"
                                    [src]="item.topBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                    alt="btn-tick" height="25" width="25">
                                <span *ngIf="item.topBtnColor === 'green'" matTooltip="APPROVED"
                                    matTooltipClass="toolTipBgColor" class="d-block c-white small-text">
                                    <ul class="list-inline" style="margin: auto 0;height: 100%;">
                                        <li *ngFor="let item of ['A','P','P','R','O','V','E','D']">
                                            {{item}}
                                        </li>
                                    </ul>
                                </span>
                            </button>
                            <!-- changeStatus(i,false,item,'img') -->
                            <button matRipple class="w-100 pb-1 d-block" style="height: 50%;"
                                (click)="openPhotoDeclineDialog(i,false,item,'img',false)"
                                [ngClass]="[item.bottomBtnColor, 'moderation-word-bg-'+item.bottomBtnColor]"
                                [disabled]="isReviewer ?  item.topBtnColor === 'purple' : item.topBtnColor === 'green'">
                                <img *ngIf="item.bottomBtnColor !== 'red'"
                                    [src]="item.bottomBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                    alt="cross-btn" height="25" width="25">
                                <span *ngIf="item.bottomBtnColor === 'red'" matTooltip="REJECTED"
                                    matTooltipClass="toolTipBgColor" class="d-block c-white small-text">
                                    <ul class="list-inline" style="margin: auto 0;height: 100%;">
                                        <li *ngFor="let item of ['R','E','J','E','C','T','E','D']">
                                            {{item}}
                                        </li>
                                    </ul>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- for moderated video -->
            <div class="col-12 px-0 text-left bg-white box-shadow-section"
                *ngIf="video && video.topBtnColor != 'orange' && video.bottomBtnColor != 'orange'">
                <div class="row mx-0">
                    <div class="" style="width: 43%;">
                        <ziggeo-player #ziggeoplayer [apiKey]="apiKey" [options]="{
                            theme:'modern',
                            height: 300,
                            width: 250,
                            video:video.videoid
                        }">
                        </ziggeo-player>
                    </div>
                    <div class="px-2" style="width: 50%;">

                        <div class="small-text c-darkergray pl-2 pt-3">
                            {{video.photouploadtime | date:'medium'}}
                            <span class="pl-2 oswald">
                                <span>
                                    <img src="../../../assets/images/static/selfie-video-camera-icon.svg"
                                        alt="">
                                </span>
                            </span>
                        </div>
                      

                        <div class="" *ngIf="video.reviewerAction === false">
                            <div class="c-darkgray small-text pl-2 pt-2">
                                By reviewer <b>{{video.reviewedBy}}</b> {{video.reviewerModerationTime | date:'medium'}}
                                <div class="small-text c-darkgray py-1">Reason for rejecting Video</div>
                            </div>

                            <div class="pl-2" *ngIf="declineReasonsByReviewer">
                                <div class="small-text c-darkergray py-1">
                                    {{declineReasonsByReviewer.split('; ')[0]}}
                                    <span class="readMore" (click)="declineDialog(true)">READ MORE</span>
                                </div>
                            </div>
                        </div>

                        <div class="" *ngIf="video.reviewerAction">
                            <div class="c-darkgray small-text pl-2 pt-2">
                                By reviewer <b>{{video.reviewedBy}}</b> {{video.reviewerModerationTime | date:'medium'}}
                            </div>
                        </div>


                        <div class="" *ngIf="video.supervisorAction === false && !video.unmoderated">
                            <div class="c-darkgray small-text pl-2 pt-2">
                                <span class="d-inline-block moderation-word-bg-red rounded-circle position-relative"
                                    style="height: 20px;width: 20px;">
                                    <img class="center-box" [src]="btnObj.cross.white" alt="tick" height="15"
                                        width="15" />
                                </span> By supervisor <b>{{video.moderatedBy}}</b> {{video.supervisorModerationTime |
                                date:'medium'}}
                                <div class="small-text c-darkgray py-1">Reason for rejecting Video</div>
                            </div>

                            <div class="pl-2" *ngIf="declineReasonsBySupervisor">
                                <div class="small-text c-darkergray py-1">
                                    {{declineReasonsBySupervisor.split('; ')[0]}}
                                    <span class="readMore" (click)="declineDialog(true)">READ MORE</span>
                                </div>
                            </div>
                        </div>


                        <div class="" *ngIf="video.supervisorAction && !video.unmoderated">
                            <!-- <span class="d-inline-block bg-green rounded-circle" style="height: 25px;width: 25px;">
                                <img [src]="btnObj.tick.white" alt="tick">
                            </span> -->
                            <div class="c-darkgray small-text pl-2 pt-2">
                                By supervisor <b>{{video.moderatedBy}}</b> {{video.supervisorModerationTime |
                                date:'medium'}}
                            </div>
                        </div>

                    </div>

                    <div class="" style="width: 7%">

                        <button matRipple class="w-100 pb-1 d-block" style="height: 50%;"
                            (click)="userVideoAction(true)" [disabled]="video.disabledselfieVideoButton"
                            [ngClass]="[video.topBtnColor, 'moderation-word-bg-'+video.topBtnColor]">
                            <img *ngIf="video.topBtnColor !== 'green'"
                                [src]="video.topBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                alt="btn-tick" height="25" width="25">
                            <span *ngIf="video.topBtnColor === 'green'" matTooltip="APPROVED"
                                matTooltipClass="toolTipBgColor" class="d-block c-white small-text">
                                <ul class="list-inline" style="margin: auto 0;height: 100%;">
                                    <li *ngFor="let item of ['A','P','P','R','O','V','E','D']">
                                        {{item}}
                                    </li>
                                </ul>
                            </span>
                        </button>
                        <button matRipple class="w-100 pb-1 d-block" style="height: 50%;" (click)="declineDialog(false)"
                            [ngClass]="[video.bottomBtnColor, 'moderation-word-bg-'+video.bottomBtnColor]">
                            <img *ngIf="video.bottomBtnColor !== 'red'"
                                [src]="video.bottomBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                alt="cross-btn" height="25" width="25">
                            <span *ngIf="video.bottomBtnColor === 'red'" matTooltip="REJECTED"
                                matTooltipClass="toolTipBgColor" class="d-block c-white small-text">
                                <ul class="list-inline" style="margin: auto 0;height: 100%;">
                                    <li *ngFor="let item of ['R','E','J','E','C','T','E','D']">
                                        {{item}}
                                    </li>
                                </ul>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>